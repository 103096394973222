import { useDayjs } from "~/composables/useDayjs";
const dayjs = useDayjs();
export const numberToTime = (number: number) => {
  var hours = Math.floor(number / 60);
  var minutes = number % 60;
  return (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
};

export const dateToQueryFormat = (date: string, time: string) => {
  if (!date || !time) return "";
  date = dayjs(date).format("YYYYMMDD");
  time = dayjs(time, "HH:mm").format("HHmm");

  return `${date}${time}`;
};

export const dateToNormalFormat = (value: string, format?: string) => {
  return {
    date: dayjs(value, "YYYYMMDDHHmm").format(format),
    time: dayjs(value, "YYYYMMDDHHmm").format("HH:mm"),
  };
};
